//custom formatter definition
 var printIcon = function(cell, formatterParams, onRendered){ //plain text value
	return '<i class="far fa-trash-alt"></i>';
};


function sendRequest(method, url, data, callback) {
	var oReq = new XMLHttpRequest();

	oReq.responseType = 'json';
	oReq.open(method, url);
	oReq.setRequestHeader("Content-Type", 'application/json');
	oReq.setRequestHeader("X-Api-Key", 'cddd579a-27b6-4d24-99b7-2d7d9d7f3177');
	oReq.send(JSON.stringify(data));
	oReq.onload = function() {
		let id = '';
		let error = null;
		switch (oReq.response.statusCode) {
			case 200:
				id = 'flash-ok';
				callback(oReq.response, id);
				break;
			case 206:
				id = 'flash-warning';
				callback(oReq.response, id);
				break;
			default:
				id = 'flash-danger';
				console.log(oReq.response, id)
				break;
		}
	};

	oReq.onerror = function() { // only triggers if the request couldn't be made at all
	  console.log(`Network Error`);
	};
}


function sendDataRequest(method, url, data)
{
	if (busy == false)
	{
		busy = true;
		sendRequest(method, url, data, function(response, id) {
			table.setData(response.data);

			if (typeof response.error !== 'undefined')
				document.getElementById(id).innerText = response.error.description;

			showFlashMessage(id);
			updateTracker();
			busy = false;
		});
	}
}

function showFlashMessage(id)
{
	document.getElementById(id).classList.add('show');
	setTimeout(() => {
		document.getElementById(id).classList.remove('show');
	}, 2000);
}

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

function updateTracker() {
	sendRequest('GET', '/v1/tracker', null, function(response) {
		for (let [key, value] of Object.entries(response.data)) {
			let track = document.getElementById('track' + capitalize(key));
			if (typeof track !== 'undefined' && track !== null) {
				track.innerText = value;
			}
		}
	});
}

function saveData() {
	sendDataRequest("POST", fileURL, table.getData());
}

function deleteElement(e, cell) {
	sendDataRequest('DELETE', deleteURL, [cell.getRow().getData()]);
}

function resetTrashFile() {
	sendDataRequest('DELETE', '/v1/files/trash', null);
}

function deleteSelectedElements() {
	var datas = table.getSelectedData();
	if (datas.length)
		sendDataRequest('DELETE', deleteURL, datas);
}

function sendSelectedElements() {
	var datas = table.getSelectedData();
	if (datas.length)
		sendDataRequest('PUT', sendURL, datas);
}

function onSelectionChange(data, row) {
	if (typeof table !== 'undefined') {
		var selectedData = table.getSelectedData().length;
		if (selectedData) {
			document.getElementById('actions').classList.remove('noSelection');
		} else {
			document.getElementById('actions').classList.add('noSelection');
		}
	}
}

function requiredCell(cell, formatterParams = null) {
	if (cell.getValue().length == 0)
		cell.getElement().classList.add('required');
	else
		cell.getElement().classList.remove('required');
	return cell.getValue();
}

function formatRow(row) {
}

function dataFrom(fieldName) {
	const values = fields[fieldName];
	return function(cell, formatterParams, onRendered) {
	    if (values[cell.getValue()] !== undefined ) {
			cell.getElement().classList.remove('required');
	        return values[cell.getValue()];
	    } else {
			cell.getElement().classList.add('required');
	        return cell.getValue();
	    }
	}
}

function makeBtnLoad(event) {
	console.log(event.target);
}

/*
 * INIT Btns
 */

let saveBtn = document.getElementById('saveBtn');
let deleteBtn = document.getElementById('deleteBtn');
let sendBtn = document.getElementById('sendBtn');
let resetBtn = document.getElementById('resetBtn');

let btns = document.querySelectorAll(".btn");

var busy = false;

saveBtn && saveBtn.addEventListener('click', saveData);
deleteBtn && deleteBtn.addEventListener('click', deleteSelectedElements);
sendBtn && sendBtn.addEventListener('click', sendSelectedElements);
resetBtn && resetBtn.addEventListener('click', resetTrashFile);
btns && btns.forEach(btn => btn.addEventListener('click', makeBtnLoad));